import { DateTime } from "luxon";

export default function useDateFormatter() {
  /**
   * Formats a date string or Date object into a localized French format
   * @param {string|Date} dateInput - Date string or JavaScript Date object
   * @param {boolean} includeTime - Whether to include time in the output
   * @returns {string|null} - Formatted date string or null if invalid
   */
  function formatDate(
    dateInput,
    includeTime = false,
    format = DateTime.DATE_FULL,
  ) {
    if (!dateInput) return null;
    if (includeTime) {
      format = DateTime.DATETIME_SHORT;
    }

    // Handle JavaScript Date object input
    if (dateInput instanceof Date) {
      return DateTime.fromJSDate(dateInput)
        .setLocale("fr-FR")
        .toLocaleString(format);
    }

    // Handle string inputs
    if (typeof dateInput === "string") {
      // Check if already in French format
      if (
        DateTime.fromFormat(dateInput, "dd/MM/yyyy", { locale: "fr-FR" })
          .isValid ||
        DateTime.fromFormat(dateInput, "dd/MM/yyyy HH:mm", { locale: "fr-FR" })
          .isValid
      ) {
        return dateInput;
      }

      // For dates with microseconds, first trim the microseconds to milliseconds
      // or remove them entirely before parsing
      let processedInput = dateInput;
      if (dateInput.includes(".") && dateInput.split(".")[1].length > 3) {
        // Extract date part and milliseconds (first 3 digits after the decimal)
        const dateParts = dateInput.split(".");
        const milliseconds = dateParts[1].substring(0, 3);
        processedInput = `${dateParts[0]}.${milliseconds}`;
      }

      // Try custom format with milliseconds (max 3 digits that Luxon supports)
      const dateTimeFromCustomWithMilli = DateTime.fromFormat(
        processedInput,
        "yyyy-MM-dd HH:mm:ss.SSS",
      );

      if (dateTimeFromCustomWithMilli.isValid) {
        return dateTimeFromCustomWithMilli
          .setLocale("fr-FR")
          .toLocaleString(format);
      }

      // Try custom format without milliseconds
      const dateTimeFromCustom = DateTime.fromFormat(
        dateInput,
        "yyyy-MM-dd HH:mm:ss",
      );

      if (dateTimeFromCustom.isValid) {
        return dateTimeFromCustom.setLocale("fr-FR").toLocaleString(format);
      }

      // Try ISO format
      const dateTimeFromIso = DateTime.fromISO(dateInput, { setZone: true });

      if (dateTimeFromIso.isValid) {
        return dateTimeFromIso.setLocale("fr-FR").toLocaleString(format);
      }
    }
    return null;
  }

  /**
   * Formats an ISO date string to yyyy/MM/dd format
   * @param {string} dateString - ISO date string
   * @returns {string|null} - Formatted date or null if invalid
   */
  function formatISODate(dateString) {
    const dt = DateTime.fromISO(dateString);
    return dt.isValid ? dt.toFormat("yyyy/MM/dd") : null;
  }

  /**
   * Parses a date string or Date object to JavaScript Date object
   * @param {string|Date} dateInput - Input to be converted to Date (same type as formatDate)
   * @returns {Date|null} - JavaScript Date object or null if parsing fails
   */
  function parseToDate(dateInput) {
    if (!dateInput) return null;
    
    // Already a Date object
    if (dateInput instanceof Date) {
      return dateInput;
    }
    
    // Handle string inputs
    if (typeof dateInput === "string") {
      // French format (dd/MM/yyyy)
      const frenchDate = DateTime.fromFormat(dateInput, "dd/MM/yyyy", { 
        locale: "fr-FR" 
      });
      if (frenchDate.isValid) {
        return frenchDate.toJSDate();
      }
      
      // French format with time (dd/MM/yyyy HH:mm)
      const frenchDateTime = DateTime.fromFormat(dateInput, "dd/MM/yyyy HH:mm", { 
        locale: "fr-FR" 
      });
      if (frenchDateTime.isValid) {
        return frenchDateTime.toJSDate();
      }
      
      // Process microseconds for custom formats
      let processedInput = dateInput;
      if (dateInput.includes(".") && dateInput.split(".")[1].length > 3) {
        const dateParts = dateInput.split(".");
        const milliseconds = dateParts[1].substring(0, 3);
        processedInput = `${dateParts[0]}.${milliseconds}`;
      }
      
      // Custom format with milliseconds
      const dateWithMs = DateTime.fromFormat(
        processedInput,
        "yyyy-MM-dd HH:mm:ss.SSS"
      );
      if (dateWithMs.isValid) {
        return dateWithMs.toJSDate();
      }
      
      // Custom format without milliseconds
      const dateWithoutMs = DateTime.fromFormat(
        dateInput,
        "yyyy-MM-dd HH:mm:ss"
      );
      if (dateWithoutMs.isValid) {
        return dateWithoutMs.toJSDate();
      }
      
      // ISO format (with timezone handling)
      const isoDate = DateTime.fromISO(dateInput, { setZone: true });
      if (isoDate.isValid) {
        return isoDate.toJSDate();
      }
    }
    
    return null;
  }
  return {
    parseToDate,
    formatDate,
    formatISODate,
  };
}
